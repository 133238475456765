import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Jammin thru the Global South was the 3+ year, 100,000+ km ride Jay did from the US to India via Latin America, Europe and Africa. Explore the photojournals at the Journey Posts tab.`}</p>
    <p>{`Jammin Global Adventures is a tour company run by Jay Kannaiyan. He organizes small group, premium motorcycle adventures in Peru, Kenya, Mongolia, India and more.`}</p>
    <p>{`If you'd like to be notified of new content or other news about Jammin Global, please subscribe.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      