import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`2013`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/jammin-india"
        }}>{`India`}</a></li>
    </ul>
    <p>{`2012`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/zambia"
        }}>{`Zambia`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/mozambique"
        }}>{`Mozambique`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/tanzania"
        }}>{`Tanzania`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/east-africa"
        }}>{`Uganda & Rwanda`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/kenya"
        }}>{`Kenya`}</a></li>
    </ul>
    <p>{`2011`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/ethiopia"
        }}>{`Ethiopia`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/sudan"
        }}>{`Sudan`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/egypt"
        }}>{`Egypt`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/europe"
        }}>{`Europe`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/grimaldi"
        }}>{`Atlantic Crossing`}</a></li>
    </ul>
    <p>{`2010`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/patagonia"
        }}>{`Patagonia`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/chile"
        }}>{`Chile`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/argentina"
        }}>{`Argentina`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/brazil"
        }}>{`Brazil`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/bolivia"
        }}>{`Bolivia`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/peru"
        }}>{`Peru`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/ecuador"
        }}>{`Ecuador`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/colombia"
        }}>{`Colombia`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/stahlratte"
        }}>{`Sailing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/central-america"
        }}>{`Central America`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/mexico"
        }}>{`Mexico`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/usa"
        }}>{`USA`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/trip-prep"
        }}>{`Trip Prep`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/background"
        }}>{`Background`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      