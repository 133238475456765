import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/faq"
        }}>{`FAQ on Adventure Tours`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/previous-journeys"
        }}>{`Previous Journeys`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/press"
        }}>{`Press`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/interviews"
        }}>{`Interviews`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/video"
        }}>{`Vidoes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/published"
        }}>{`Published`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/terms"
        }}>{`Terms & Conditions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/privacy"
        }}>{`Privacy Policy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/contact"
        }}>{`Contact`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/"
        }}>{`Home`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      