/** @jsx jsx */
import { jsx } from "theme-ui"
import { Grid } from "@theme-ui/components"
import { graphql, StaticQuery } from "gatsby"
import Listing from "./listing"
import MailChimp from "./MailChimp"
// @ts-ignore
import FooterRight from "../texts/footer-right.mdx"
// @ts-ignore
import FooterLeft from "../texts/footer-left.mdx"
// @ts-ignore
import FooterLeftNav from "../texts/footer-left-nav.mdx"



const Footer = () => {

  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          allPost(
            sort: {
              order: DESC, 
              fields: date
            }, 
            filter: {
              banner: {
                childImageSharp: {
                  fluid: {
                    src: {
                      regex: "/jpg|png/"
                    }
                  }
                }
              }
            }, 
            limit: 7
          ) {
            nodes {
              slug
              title
              date(formatString: "MMM DD, YYYY")
              tags {
                name
                slug
              }
              banner {
                childImageSharp {
                  fluid(
                    maxWidth: 200,
                    maxHeight: 50,
                    quality: 80,
                    srcSetBreakpoints: [200]
                  ) {
                    src
                    srcSet
                    aspectRatio
                    sizes
                    base64
                  }
                }
              }
            }
          }
        }
      `}

      render={data => (

        <footer
          sx={{
            boxSizing: `border-box`,
            display: `flex`,
            justifyContent: `space-between`,
            mt: [6],
            color: `secondary`,
            a: {
              variant: `links.secondary`,
            },
            flexDirection: [`column`, `row`, `row`],
            variant: `dividers.top`,
          }}
        >
          <Grid
            gap={4}
            columns={[ 1, 3, 3 ]}
            sx={{
            }}
          >
            <section
              aria-label="Left Footer Column"
              sx={{
                padding: '2%',
                borderRight: '1px solid #333',
                mb: [3, 4, 5],
              }}
            >
              <section
                sx={{
                  mb: [2, 3, 4],
                }}
              >
                <h2>About</h2>
              </section>
              <section
                sx={{
                  mb: [2, 3, 4],
                }}
              >
                <FooterLeft />
              </section>
              <section 
                sx={{
                  mb: [3, 4, 5]
                }}
              >
                <MailChimp />
              </section>

              <section
                sx={{
                  mb: [2, 3, 4],
                  ul: { margin: 0, padding: 0 },
                  li: { listStyle: `none`, mb: 1 },
                  a: { variant: `links.footerList` },
                }}
              >
                <FooterLeftNav />
              </section>
            </section>

            <section
              aria-label="Middle Footer Column"
              sx={{
                padding: '3%',
                borderRight: '1px solid #333',
              }}
            >
              <h2>Latest Content</h2>
              <Listing
                posts={data.allPost.nodes}
                isFooter={true}
                sx={{
                  mb: [1, 2, 2]
                }}
              />

            </section>

            <section
              aria-label="Right Footer Column"
              sx={{
                padding: '1%',
                // borderLeft: '1px solid #333',
                mb: [3, 4, 5],
              }}
            >
              <h2>Blog Posts by Country</h2>
              <section
                sx={{
                  ul: { margin: 0, padding: 0 },
                  li: { listStyle: `none`, mb: 1 },
                  a: { variant: `links.footerList` },
                }}
              >
                <FooterRight />
              </section>
            </section>
          </Grid>
        </footer>
      )}
    />
  )
}

// export const query = 


export default Footer