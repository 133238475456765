/** @jsx jsx */
import React from "react"
import { jsx, Styled } from "theme-ui"
import { Box } from "@theme-ui/components"
import { Link } from "gatsby"
import ItemTags from "./item-tags"
import Img from "gatsby-image"

type BlogListItemProps = {
  post: {
    slug: string
    title: string
    date: string
    excerpt: string
    description: string
    timeToRead?: number
    tags?: {
      name: string
      slug: string
    }[]
    banner?: {
      childImageSharp: {
        fluid
      }
    }
  }
  showTags?: boolean
}

const BlogListItem = ({ post, showTags = true }: BlogListItemProps) => {
  let s = post.description
  let limit = 140
  const trimmedDescription = s.length > limit ? s.substring(0, limit - 3) + '...' : s

  return (
    <Box mb={2}>
      <Styled.a as={Link} to={post.slug} >
        {post.title.length <= 44 ? 
          (
            <>
              <section
                sx={{
                  // width: ['100%', '50%', '25%'],
                  fontSize: [1, 2, 3],
                  mb: 4
                }}
              >
                {post.title}
              </section>
              <section
                sx={{
                  mt: 1,
                  pt: 2,
                  img: {
                    borderRadius: '5px',
                    objectPosition: '50% 30%',
                  },
                  ":hover": {
                    transition: 'all 0.4s ease',
                    transform: 'translate3D(0, -1px, 0) scale(1.02)'
                  }
                }}
              >
                {post.banner &&
                  <Img
                    imgStyle={{
                      img: {
                        borderRadius: '5px',
                      },
                    }}
                    fluid={post.banner.childImageSharp.fluid}
                  />
                }
              </section>
            </>
          ) : (
            <>
              <section
                sx={{
                  // width: ['100%', '50%', '25%'],
                  fontSize: [1, 2, 3],
                }}
              >
                {post.title}
              </section>
              <section
                sx={{
                  mt: 1,
                  img: {
                    borderRadius: '5px',
                    objectPosition: '50% 30%',
                  },
                  ":hover": {
                    transition: 'all 0.4s ease',
                    transform: 'translate3D(0, -1px, 0) scale(1.02)'
                  }
                }}
              >
                {post.banner &&
                  <Img
                    imgStyle={{
                      img: {
                        borderRadius: '5px',
                      },
                    }}
                    fluid={post.banner.childImageSharp.fluid}
                  />
                }
              </section>
            </>
          )
        }
        
        <section
          sx={{
            mt: 1,
            fontSize: [1, 1, 2],
            color: `secondary`,
          }}
        >
          {trimmedDescription}
        </section>
      </Styled.a>
      <section
        sx={{
          mt: 1,
          fontSize: [1, 1, 1],
          color: `tertiary`,
          a: {
            variant: `links.tertiary`
          },
        }}
      >
        <time>{post.date}</time>
        {post.tags && showTags && (
          <React.Fragment>
            {` - `}
            <ItemTags tags={post.tags} />
          </React.Fragment>
        )}
      </section>
    </Box>
  )
}

export default BlogListItem
