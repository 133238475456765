/** @jsx jsx */
import { jsx } from "theme-ui"
import { Grid } from "@theme-ui/components"
import BlogListItem from "./blog-list-item"
import FooterLatestPostsItem from "./footer-latest-posts-item"

type ListingProps = {
  posts: {
    slug: string
    title: string
    date: string
    excerpt: string
    description: string
    timeToRead?: number
    tags?: {
      name: string
      slug: string
    }[]
    banner?: {
      childImageSharp: {
        fluid
      }
    }
  }[]
  className?: string
  showTags?: boolean
  isFooter?: boolean
}

const Listing = ({ posts, className, showTags = true, isFooter = false }: ListingProps) => {
  
  return (

    !isFooter ?  (
      <section sx={{ mb: [5, 5, 5] }} className={className}>
        <Grid
          gap={4}
          columns={[1, 2]}
        >
          {posts && posts.map((post) => {
            if (post.tags) {
              return <BlogListItem key={post.slug} post={post} showTags={showTags} />
            }
          })}
        </Grid>
      </section>
    ) : (
      <section sx={{ mb: [2, 3, 4] }} className={className}>
        <Grid
          gap={4}
          columns={1}
        >
          {posts && posts.map((post) => {
            if (post.tags && post.banner) {
              return <FooterLatestPostsItem key={post.slug} post={post} showTags={showTags} />
            }
          })}
        </Grid>
      </section>
    )
  )
}

export default Listing
